const styles = theme => ({
  titleContainer: {
    marginBottom: '25px',
    width: '100%',
  },
  title: {
    display: 'inline-block',
    borderRadius: '15px',
    background: '#912d43',
    padding: '10px 20px',
    fontWeight: '500',
    // fontFamily: `'Syne', sans-serif !important;`,
    color: '#fff',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrow: {
    border: 'solid black',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: '3px',
    marginLeft: '5px',
  },
  up: {
    transform: 'rotate(-135deg)',
    '-webkit-transform': 'rotate(-135deg)',
  },
  down: {
    transform: 'rotate(45deg)',
    '-webkit-transform': 'rotate(45deg)',
    marginBottom: '4px',
  },
  videoWrapper: {
    transition: 'opacity 1s ease-in-out',
  },
  videoWrapperVisible: {
    display: 'block',
    opacity: 1,
  },
  videoWrapperInvisible: {
    display: 'none',
    opacity: 0,
  },
  videoContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  videoStep: {
    '& h1': {
      marginTop: '0',
      marginBottom: '0',
      fontSize: '14px',
    },
    '& iframe': {
      width: '100%',
      height: '300px',
    },
    borderRadius: '15px',
    background: 'rgba(81, 18, 39, 0.4)',
    width: '32%',
    padding: '30px 20px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      width: '100%',
    },
  },
  videoZapContainer: {
    textAlign: 'center',
    marginTop: '30px',
  },
});

export default styles;
