import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Grid, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CompoundInterestChart from './CompoundInterestChart.js';
import { formatApy } from '../../../helpers/format.js';

const useStyles = makeStyles(styles);

const getApyForPeriod = (apy, period) => {
  const curApy = apy.totalApy;

  if (period === 1) {
    return yearlyToDaily(curApy);
  } else {
    return yearlyToMonthly(curApy);
  }
};

const Calculator = ({ pools, apys, setSummary }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let maxApy = 0;
  let maxApyPoolId = null;
  Object.entries(apys).forEach(([key, value]) => {
    const poolExists = pools.find(pool => pool.id === key);
    if (poolExists && value.totalApy > maxApy) {
      maxApy = value.totalApy;
      maxApyPoolId = key;
    }
  });

  const [selectedPool, setSelectedPool] = React.useState(
    pools.find(pool => pool.id === maxApyPoolId)
  );
  const [selectedPoolId, setSelectedPoolId] = React.useState(maxApyPoolId);

  const handlePoolChange = event => {
    if (!event.target.value) {
      return;
    }

    setSelectedPool(pools.find(pool => pool.id === event.target.value));
    setSelectedPoolId(event.target.value);

    const newState = { ...state };
    newState.growthRate = getApyForPeriod(apys[event.target.value], newState.period);
    setState(newState);
  };

  const handleInputAmountChange = event => {
    if (!event.target.value) {
      const newState = { ...state };
      newState.initialAmount = Number('0');
      setState(newState);
      return;
    }

    const newState = { ...state };
    newState.initialAmount = Number(event.target.value);
    setState(newState);
  };

  const handlePeriodChange = event => {
    if (!event.target.value) {
      return;
    }

    const newState = { ...state };
    newState.period = Number(event.target.value);
    newState.growthRate = getApyForPeriod(apys[selectedPoolId], newState.period);
    setState(newState);
  };

  const periods = [
    { key: 1, value: '1 month' },
    { key: 3, value: '3 months' },
    { key: 6, value: '6 months' },
    { key: 12, value: '12 months' },
  ];

  const defaultState = {
    initialAmount: 2500,
    period: 12,
    growthRate: getApyForPeriod(apys[maxApyPoolId], 12),
  };
  const [state, setState] = React.useState(defaultState);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!data || data.length <= 0) {
      return;
    }

    const toNumber = value => {
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    const finalAmount = toNumber(data[data.length - 1].value);

    const periodLabel = periods.find(period => period.key === state.period).value;

    setSummary(
      'With our great rate of ' +
        formatApy(apys[selectedPoolId].totalApy) +
        ' APY* your $' +
        state.initialAmount +
        ' ' +
        selectedPool.name +
        ' deposit would be worth $' +
        finalAmount +
        ' after ' +
        periodLabel +
        '.'
    );
  }, [selectedPoolId, state, data]);

  return (
    <div>
      <h3 style={{ textAlign: 'center', marginBottom: '5px' }}>
        Use our calculator to see how much your assets could grow
      </h3>
      <Grid container className={classes.toolbar}>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.showDetailLeft}>
            {t('Invest')}:{' '}
            <FormControl className={classes.zapFormControl}>
              <Select
                variant="standard"
                className={classes.zapSelect}
                value={selectedPoolId}
                onChange={handlePoolChange}
              >
                {pools.map(pool => (
                  <MenuItem key={pool.id} value={pool.id}>
                    {pool.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.amountContainer}>
          <span className={classes.amountLabel}>Deposit:</span>
          <FormControl variant="outlined" className={classes.amountInput}>
            <OutlinedInput
              value={state.initialAmount > 0 ? state.initialAmount : ''}
              onChange={handleInputAmountChange}
              startAdornment={'$'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.periodContainer}>
          <div className={classes.showDetailLeft}>
            {t('Duration')}:{' '}
            <FormControl className={classes.zapFormControl}>
              <Select
                variant="standard"
                className={classes.zapSelect}
                value={state.period}
                onChange={handlePeriodChange}
              >
                {periods.map(period => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>

      <div className={classes.chartContainer}>
        <CompoundInterestChart {...state} setData={data => setData(data)} />
      </div>
    </div>
  );
};

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const yearlyToMonthly = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 12) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

export default Calculator;
