const styles = theme => ({
  scroller: {
    background: '#f9f9f9',
    borderRadius: '15px',
    padding: '15px 25px',
    width: '100%',
  },
  titleContainer: {
    borderRadius: '15px',
    background: '#402533',
    padding: '25px 20px',
    marginTop: '25px',
    marginBottom: '25px',
    width: '100%',
  },
  title: {
    fontWeight: '500',
    // fontFamily: `'Syne', sans-serif !important;`,
    color: '#fff',
    fontSize: '20px',
  },
  separator: {
    width: '50px',
    borderBottom: `4px solid ${theme.palette.text.red}`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtitle: {
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '8px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
    },
    fontWeight: '550',
    color: theme.palette.text.primary,
  },
  grid: {
    marginTop: '0',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  gridLargeCol: {
    borderRadius: '15px',
    backgroundColor: 'rgba(81, 18, 39, 0.4)',
    width: '73%',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  gridSmallCol: {
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  sidebarSection: {
    borderRadius: '15px',
    backgroundColor: 'rgba(81, 18, 39, 0.4)',
    padding: '10px',
    marginTop: '20px',
  },
  sidebarSectionFirst: {
    marginTop: 0,
  },
  highlightRed: {
    fontWeight: 'bold',
    color: 'rgb(245, 23, 70)',
  },
  floatRight: {
    float: 'right',
  },
  showDetailButton: {
    display: 'block',
    margin: '3px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    minWidth: '110px',
    width: '70%',
    background: 'transparent',
    border: '1px solid #4b4f5b',
    '& img': {
      height: '14px',
      verticalAlign: 'middle',
    },
    '& span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: 'transparent',
      border: '1px solid #fff',
    },
  },
  showDetailButtonContained: {
    color: '#fff',
    backgroundColor: theme.palette.background.red,
    border: '1px solid ' + theme.palette.background.red,
    padding: '20px 40px',
    '& .MuiButton-label': {
      color: 'white',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#a81131',
      border: '1px solid #a81131',
    },
  },
});

export default styles;
