const styles = theme => ({
  scroller: {
    background: '#f9f9f9',
    borderRadius: '15px',
    padding: '15px 25px',
    width: '100%',
  },
  titleContainer: {
    borderRadius: '15px',
    background: '#402533',
    padding: '25px 20px',
    marginTop: '25px',
    marginBottom: '25px',
    width: '100%',
  },
  title: {
    fontWeight: '500',
    // fontFamily: `'Syne', sans-serif !important;`,
    color: '#fff',
    fontSize: '20px',
  },
  separator: {
    width: '50px',
    borderBottom: `4px solid ${theme.palette.text.red}`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtitle: {
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '8px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
    },
    fontWeight: '550',
    color: theme.palette.text.primary,
  },
});

export default styles;
