export const bscPools = [
  {
    id: 'bomb-phub',
    logo: 'single-assets/PHUB.png',
    name: 'PegHub',
    token: 'PHUB',
    tokenDescription: 'PegHub.com',
    tokenAddress: '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    tokenDecimals: 18,
    earnedToken: 'bombfarmPHUB',
    earnedTokenAddress: '0x7d18710BC1d1c55f41652Aa00643289BF3CAB4E5',
    earnContractAddress: '0x7d18710BC1d1c55f41652Aa00643289BF3CAB4E5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PHUB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PegHub',
    assets: ['PHUB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    bgColor: 'rgb(245, 23, 70)',
    zapTokens: ['BNB'],
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    createdAt: 1648477070,
  },
  {
    id: 'bitbomb-bitbtc-btcb',
    name: 'Bitcoin',
    token: 'BITBTC-BTCB LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x601E4fAbFC98933b60DcE8d198b936F2D2D5aA09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITBTC-BTCB',
    earnedTokenAddress: '0x7fDA4FB84495d8068452983a35bFfd4454aa1D97',
    earnContractAddress: '0x7fDA4FB84495d8068452983a35bFfd4454aa1D97',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitbtc-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITBTC', 'BTCB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(255, 244, 232)',
    zapTokens: ['BNB', 'BTCB'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb',
    buyTokenUrl:
      'https://swap.peghub.com/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  },

  {
    id: 'bitbomb-bitdot-dot',
    name: 'Polkadot',
    token: 'BITDOT-DOT LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0xcd47cB7E1e0E9a4DEc4a1C02A3a6bACE79134622',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITDOT-DOT',
    earnedTokenAddress: '0x78343Cf1Ba99dfdb577C22beD83cc9F9A779d16C',
    earnContractAddress: '0x78343Cf1Ba99dfdb577C22beD83cc9F9A779d16C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitdot-dot',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITDOT', 'DOT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(255, 234, 246)',
    zapTokens: ['BNB', 'DOT'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402/0x123c62f04c9D70bD6F04E6282434c728F04eBD50',
    buyTokenUrl:
      'https://swap.peghub.com/swap?outputCurrency=0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  },
  {
    id: 'bitbomb-bitatom-atom',
    name: 'Cosmos',
    token: 'BITATOM-ATOM LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0xBc60EDc20e537a85F1EB91f471e32A8839B92722',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITATOM-ATOM',
    earnedTokenAddress: '0xd55de3458A7986e43f734d2bdc51d7D9269BA470',
    earnContractAddress: '0xd55de3458A7986e43f734d2bdc51d7D9269BA470',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitatom-atom',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITATOM', 'ATOM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(240, 240, 240)',
    zapTokens: ['BNB', 'ATOM'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0x0Eb3a705fc54725037CC9e008bDede697f62F335/0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d',
    buyTokenUrl:
      'https://swap.peghub.com/swap?outputCurrency=0x0Eb3a705fc54725037CC9e008bDede697f62F335',
  },
  {
    id: 'bitbomb-bitada-ada',
    name: 'Cardano',
    token: 'BITADA-ADA LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x3E784ce022D55bCfD87F6D7281fE5aAcD6e44018',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITADA-ADA',
    earnedTokenAddress: '0xa00260f157C2b2997C58DA2b1F89A61f8C5Ce796',
    earnContractAddress: '0xa00260f157C2b2997C58DA2b1F89A61f8C5Ce796',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitada-ada',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITADA', 'ADA'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(233, 240, 255)',
    zapTokens: ['BNB', 'ADA'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453',
    buyTokenUrl:
      'https://swap.peghub.com/swap?outputCurrency=0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
  },
  {
    id: 'bomb-czbnb-bnb',
    name: 'BNB',
    token: 'CZBNB-BNB LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x6773C6b86c15A9c10219eb92c036DE3F8F2ae956',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZBNB-BNB',
    earnedTokenAddress: '0x881598931619636D4A3377219A702a80F24D6505',
    earnContractAddress: '0x881598931619636D4A3377219A702a80F24D6505',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czbnb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZBNB', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(255, 244, 232)',
    zapTokens: ['BNB'],
    addLiquidityUrl: 'https://swap.peghub.com/add/BNB/0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
    buyTokenUrl: 'https://swap.peghub.com/swap?outputCurrency=BNB',
  },
  {
    id: 'bomb-czbusd-busd',
    name: 'BUSD',
    token: 'CZBUSD-BUSD LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x16254Cd930547e9591cdF4e3594f32857d106E12',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZBUSD-BUSD',
    earnedTokenAddress: '0x447D793c9ff744a88758eADa0E90DaCF3B8534bE',
    earnContractAddress: '0x447D793c9ff744a88758eADa0E90DaCF3B8534bE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czbusd-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZBUSD', 'BUSD'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(255, 244, 232)',
    zapTokens: ['BNB', 'BUSD'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    buyTokenUrl:
      'https://swap.peghub.com/swap?outputCurrency=0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21',
  },
];
