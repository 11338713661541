import * as React from 'react';
import {
  Label,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useEffect } from 'react';

const months = [
  { key: 1, value: 'Jan' },
  { key: 2, value: 'Feb' },
  { key: 3, value: 'Mar' },
  { key: 4, value: 'Apr' },
  { key: 5, value: 'May' },
  { key: 6, value: 'Jun' },
  { key: 7, value: 'Jul' },
  { key: 8, value: 'Aug' },
  { key: 9, value: 'Sep' },
  { key: 10, value: 'Oct' },
  { key: 11, value: 'Nov' },
  { key: 12, value: 'Dec' },
];

function formatValue(value) {
  let order = Math.floor(Math.log10(value) / 3);
  if (order < 0) {
    order = 0;
  }

  const num = value / 1000 ** order;
  const units = ['', 'k', 'M', 'B', 'T', 'Q', 'Q', 'S', 'S'];

  return num.toFixed(num % 1 !== 0 ? 1 : 0) + units[order];
}

function CompoundInterestChart({ initialAmount, period, growthRate, setData }) {
  const data = React.useMemo(() => {
    const result = [];
    let axisPeriod = period;
    if (period <= 1) {
      axisPeriod = 30;
    }

    result.push({
      // label: `${periodType === 'months' ? months.find(month => month.key === i).value : i}`,
      label: `0`,
      value: initialAmount,
    });

    for (let i = 1; i <= axisPeriod; i++) {
      let lastFutureValue = initialAmount;
      if (result.length > 0) {
        lastFutureValue = result[result.length - 1].value;
      }
      result.push({
        // label: `${periodType === 'months' ? months.find(month => month.key === i).value : i}`,
        label: `${i}`,
        value: lastFutureValue * Math.pow(1 + growthRate, 1),
      });
    }

    return result;
  }, [initialAmount, period, growthRate]);

  let periodType;
  if (period <= 1) {
    periodType = 'Day';
  } else {
    periodType = 'Month';
  }

  function renderTooltip({ active, payload, label }) {
    if (active && payload != null && payload[0] != null) {
      return (
        <div className="peghub-tooltip">
          <p>
            Value on {periodType} {label}:
          </p>
          <p>${payload[0].value.toFixed(2)}</p>
        </div>
      );
    } else {
      return '';
    }
  }

  useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <>
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tick={{ fill: 'white' }}>
              <Label offset={-3} value={periodType} fill="white" position="insideBottom" />
            </XAxis>
            <YAxis tick={{ fill: 'white' }} tickFormatter={value => formatValue(value)} />
            <Tooltip content={renderTooltip} />
            <Line type="monotone" dataKey="value" stroke="rgb(245, 23, 70)" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default CompoundInterestChart;
