import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Avatar } from '@material-ui/core';
import { formatApy } from '../../../helpers/format.js';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';

const useStyles = makeStyles(styles);

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const HeroCoins = ({ pools, apys }) => {
  const classes = useStyles();

  const singleAssetRequire = require.context('images/single-assets', false, /\.(svg|webp|png)$/);
  const singleAssets = Object.fromEntries(
    singleAssetRequire.keys().map(path => [path.substring(2, path.lastIndexOf('.')), path])
  );

  const [windowWidth, setWindowWidth] = useState(window ? window.innerWidth : 0);

  useEffect(() => {
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth);
    });
  }, [window.innerWidth]);

  let numItems = 5;
  if (windowWidth <= 1230 && windowWidth > 960) {
    numItems = 4;
  } else if (windowWidth <= 960 && windowWidth > 600) {
    numItems = 3;
  } else if (windowWidth <= 600) {
    numItems = 2;
  }

  const sortedPools = pools
    .filter(pool => pool.status === 'active')
    .sort((a, b) => {
      const tvlA = Number(BigNumber(a.tvl).times(a.oraclePrice).toFixed(2));
      const tvlB = Number(BigNumber(b.tvl).times(b.oraclePrice).toFixed(2));
      if (a.id === 'bomb-phub') {
        return 1;
      }
      if (b.id === 'bomb-phub') {
        return -1;
      }
      return tvlB - tvlA;
    });

  const batchedPools = sliceIntoChunks(
    sortedPools.filter(pool => {
      return pool.assets.length > 1;
    }),
    numItems
  );

  return (
    <div className={classes.carouselContainer}>
      <Carousel
        emulateTouch={true}
        infiniteLoop={true}
        centerMode={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        {batchedPools &&
          batchedPools.map((curPools, index) => (
            <div key={'poolsbatch' + index} className={classes.coinsContainer}>
              {curPools.map(pool => (
                <Link
                  key={pool.id}
                  to={`/vault/${pool.chain}/${pool.id}`}
                  style={{
                    display: 'inline-block',
                    marginRight: '2%',
                    width: 100 / numItems - 2 + '%',
                    color: '#000',
                  }}
                >
                  <div className={classes.coinContainer}>
                    <div className={classes.coinAvatarContainer}>
                      <Avatar
                        className={classes.coinAvatar}
                        src={
                          pool.logo
                            ? require('images/' + pool.logo)
                            : require.context(
                                'images/single-assets',
                                false,
                                /\.(svg|webp|png)$/
                              )(singleAssets[pool.assets[1]])
                        }
                        alt={pool.token}
                        variant="square"
                        imgProps={{ style: { objectFit: 'contain' } }}
                      />
                    </div>
                    <div className={classes.coinInfoContainer}>
                      <h3>{pool.name}</h3>
                      <p className={classes.apyValue}>
                        {formatApy((apys[pool.id] || { totalApy: 0 }).totalApy)}
                      </p>
                      <p className={classes.apyTitle}>APY</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default HeroCoins;
