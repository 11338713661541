import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={`${classes.topTitleContainer}`}>
        <div className={classes.topTitle}>Join the Peghub community</div>
        <div className={classes.topTitleSeparator}>&nbsp;</div>
        <div className={classes.topTitleSocialIcons}>
          <a
            href="https://t.me/Peghub"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <i className={`fab fa-telegram ${classes.linkIcon}`} />
          </a>

          <a
            href="https://discord.gg/f54zGW7UsJ"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <i className={`fab fa-discord ${classes.linkIcon}`} />
          </a>

          <a
            href="https://twitter.com/peghubdao"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <i className={`fab fa-twitter ${classes.linkIcon}`} />
          </a>

          <a
            href="https://bombbshare.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <i className={`fab fa-medium ${classes.linkIcon}`} />
          </a>
        </div>
      </div>
      <div className={`${classes.container} relative`}>
        <div className={classes.bubble}>
          <div>
            <img alt="Bubble" src={require(`assets/img/footer_bubble.png`)} />
          </div>
        </div>
        <Grid container className={classes.subcontainer}>
          <Grid item xs={3} className={classes.containerItem}>
            <img
              alt="PegHub"
              src={require(`images/peghub.png`)}
              height={'30px'}
              className={classes.logo}
            />
          </Grid>

          <Grid item xs={6} className={classes.containerItem}>
            <div className={classes.copyright}>
              Copyright &copy;2022 PegHub. All rights reserved.
            </div>
          </Grid>

          <Grid item xs={3} className={classes.containerItem}>
            <Grid container className={classes.links}>
              <Hidden smDown>
                <Grid item xs={3}>
                  <a href="/" target="_blank" rel="noopener noreferrer" className={classes.link}>
                    <span>{t('Home')}</span>
                  </a>
                </Grid>

                <Grid item xs={3}>
                  <a
                    href="https://app.peghub.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <span>{t('App')}</span>
                  </a>
                </Grid>

                <Grid item xs={3}>
                  <a
                    href="https://swap.peghub.com/swap"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    <span>{t('Swap')}</span>
                  </a>
                </Grid>
              </Hidden>

              <Grid item xs={3}>
                <a
                  href="https://docs.peghub.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <span>{t('Docs')}</span>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default memo(Footer);
