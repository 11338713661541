import { whiteColor } from '../../../../../assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props => (props.align ? props.align : 'center'),
    justifyContent: 'center',
  },
  stat: {
    fontSize: '16px',
    fontWeight: 'bold',
    // color: theme.palette.text.red,
    color: '#d87f15',
    lineHeight: '16px',
    letterSpacing: 0,
    position: 'relative',
    marginTop: '3px',
  },
  substat: {
    fontSize: '18px',
    fontWeight: '400',
    color: theme.palette.text.secondary,
    width: '100%',
    textAlign: 'center',
  },
  label: {
    fontSize: '14px',
    fontWeight: '400',
    color: whiteColor,
    lineHeight: '14px',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  boosted: {
    color: '#2a9e46',
    position: 'absolute',
    top: '-20px',
    left: 0,
    right: 0,
  },
  crossed: {
    textDecoration: 'line-through',
  },
});

export default styles;
