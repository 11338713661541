const styles = theme => ({
  carouselContainer: {
    borderRadius: '15px',
    overflow: 'hidden',
    '& .control-arrow': {
      backgroundColor: '#000 !important',
    },
  },
  coinsContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    textAlign: 'left',
    paddingLeft: '35px',
    paddingRight: '35px',
  },
  coinContainer: {
    width: '100%',
    display: 'flex',
    borderRadius: '15px',
    padding: '5px',
    paddingLeft: '30px',
    alignItems: 'center',
    backgroundColor: '#402533',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
    },
  },
  coinAvatar: {
    width: '75px',
    height: '75px',
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px',
    },
  },
  coinInfoContainer: {
    flex: 1,
    textAlign: 'center',
    color: '#fff',
    '& h3': {
      marginTop: '16px',
      marginBottom: 0,
    },
  },
  apyValue: {
    marginTop: 0,
    marginBottom: 0,
  },
  apyTitle: {
    marginTop: 0,
    marginBottom: '16px',
  },
});

export default styles;
