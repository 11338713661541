import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { useConnectWallet, useDisconnectWallet } from '../../../home/redux/hooks';
import { useFetchApys, useFetchBalances, useFetchVaultsData } from '../../redux/hooks';
import { byDecimals } from 'features/helpers/bignumber';
import { formatTvl } from 'features/helpers/format';
import HomeLink from './HomeLink/HomeLink';
import PoolActions from '../PoolActions/PoolActions';
import Calculate from '../Calculate/Calculate';
import LabeledStat from '../PoolSummary/LabeledStat/LabeledStat';
import styles from './styles';
import { Helmet } from 'react-helmet';
import { usePageMeta } from '../../../common/getPageMeta';
import ApyStats from '../PoolSummary/ApyStats/ApyStats';
import PoolPaused from '../PoolSummary/PoolPaused/PoolPaused';
import { CakeV2Banner } from './Banners/CakeV2Banner/CakeV2Banner';
import { getNetworkFriendlyId, launchpools } from '../../../helpers/getNetworkData';
import {
  useLaunchpoolSubscriptions,
  useLaunchpoolUpdates,
  usePoolApr,
} from '../../../stake/redux/hooks';
import { getRetireReason } from '../PoolSummary/RetireReason/RetireReason';
import { Button } from '@material-ui/core';
import { NetworkConnectNotice } from '../../../../components/NetworkConnectNotice/NetworkConnectNotice.js';
import { createWeb3Modal, fetchPrice } from '../../../web3/index.js';
import Avatar from '@material-ui/core/Avatar';
import VideoGuide from '../../../../components/VideoGuide/VideoGuide.js';

const FETCH_INTERVAL_MS = 30 * 1000;

const useStyles = makeStyles(styles);

const formatDecimals = number => {
  return number >= 10 ? number.toFixed(4) : number.isEqualTo(0) ? 0 : number.toFixed(8);
};

const formatDecimalsRounded = number => {
  return number >= 10 ? number.toFixed(4) : number.isEqualTo(0) ? 0 : number.toFixed(4);
};

const PoolDetails = ({ vaultId, chain }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { connectWallet, web3, address, networkId, connected } = useConnectWallet();
  const { pools, fetchVaultsData, fetchVaultsDataDone } = useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const poolIndex = pools.findIndex(p => p.id === vaultId);
  const pool = useSelector(state => state.vault.pools[poolIndex]);
  const { getPageMeta } = usePageMeta();
  const { subscribe } = useLaunchpoolSubscriptions();
  const activeLaunchpools = useSelector(state => state.vault.vaultLaunchpools[pool.id]);
  const launchpoolId = useSelector(state => state.vault.vaultLaunchpool[pool.id]);
  const launchpool = launchpoolId ? launchpools[launchpoolId] : null;
  const launchpoolApr = usePoolApr(launchpoolId);
  const multipleLaunchpools = activeLaunchpools.length > 1;
  const [activeTab, setActiveTab] = useState('deposit');

  useEffect(() => {
    const unsubscribes = activeLaunchpools.map(launchpoolId =>
      subscribe(launchpoolId, {
        poolApr: true,
        poolFinish: true,
      })
    );

    return () => unsubscribes.forEach(unsubscribe => unsubscribe());
  }, [subscribe, activeLaunchpools]);

  useLaunchpoolUpdates();

  const { zap, eligibleTokens } = useMemo(() => {
    const zap = pool.zap;
    return {
      zap,
      eligibleTokens: [
        {
          name: pool.name,
          symbol: pool.token,
          address: pool.tokenAddress,
          decimals: pool.tokenDecimals,
          logoURI: pool.logo,
        },
        ...(zap ? zap.tokens : []),
      ],
    };
  }, [pool.tokenAddress]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && zap) {
        const tokens = {};
        eligibleTokens.forEach(token => {
          tokens[token.symbol] = {
            tokenAddress: token.wrappedSymbol ? null : token.address,
            tokenBalance: 0,
            tokenChain: pool.chain,
            allowance: {
              [zap.zapAddress]: token.wrappedSymbol ? Infinity : 0,
            },
            decimals: token.decimals,
            ...tokens[token.symbol],
          };
        });
        tokens[pool.earnedToken] = {
          tokenAddress: pool.earnedTokenAddress,
          tokenBalance: 0,
          tokenChain: pool.chain,
          allowance: {
            [zap.zapAddress]: 0,
          },
          decimals: pool.earnedTokenDecimals,
          ...tokens[pool.earnedToken],
        };
        fetchBalances({ address, web3, tokens });
      } else {
        fetchBalances({ address, web3, tokens });
      }
      fetchVaultsData({ address, web3, pools });
      fetchApys();
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);

  useEffect(() => {
    setModal(createWeb3Modal(t));
  }, [setModal, t]);

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet]);

  const connectWalletCallback = useCallback(() => {
    connectWallet(web3Modal);
  }, [web3Modal, connectWallet]);

  const disconnectWalletCallback = useCallback(() => {
    disconnectWallet(web3, web3Modal);
  }, [web3, web3Modal, disconnectWallet]);

  const vaultStateTitle = useMemo(() => {
    let state =
      pool.status === 'eol'
        ? t(getRetireReason(pool.retireReason))
        : pool.depositsPaused
        ? t('Vault-DepositsPausedTitle')
        : null;

    if (launchpool) {
      state = t('Stake-BoostedBy', { name: launchpool.name });
    }

    if (pool.experimental) {
      state = t('Vault-Experimental');
    }

    return state === null ? (
      ''
    ) : (
      <PoolPaused
        message={t(state)}
        isBoosted={!!launchpool}
        isExperimental={!!pool.experimental}
      />
    );
  }, [pool, launchpool, t]);

  const tokenSingle = tokens[pool.assets.length > 1 ? pool.assets[1] : pool.assets[0]];
  const balanceSingle = byDecimals(
    tokenSingle ? tokenSingle.tokenBalance : 0,
    tokenSingle ? tokenSingle.tokenDecimals : 18
  );
  const sharesBalance = new BigNumber(tokens[pool.earnedToken].tokenBalance);
  const apy = apys[pool.id] || { totalApy: 0 };

  const balanceUsd =
    balanceSingle > 0 && fetchVaultsDataDone ? formatTvl(balanceSingle, pool.oraclePrice) : '-';
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
    pool.tokenDecimals
  );
  const depositedUsd =
    deposited > 0 && fetchVaultsDataDone ? formatTvl(deposited, pool.oraclePrice, false) : '-';
  const depositedUsdNumber = Number(BigNumber(deposited).times(pool.oraclePrice).toFixed(2));

  const [initialDeposit, setInitialDeposit] = React.useState(
    deposited > 0 && fetchVaultsDataDone ? depositedUsdNumber : 1000
  );

  if (!pool) {
    return (
      <>
        <HomeLink />
        <div className={classes.container}>
          <div className={classes.error}>Vault {vaultId} not found</div>
        </div>
      </>
    );
  }

  if (!connected || networkId !== getNetworkFriendlyId(chain)) {
    return (
      <NetworkConnectNotice
        web3={web3}
        address={address}
        connectWallet={connectWalletCallback}
        disconnectWallet={disconnectWalletCallback}
        networkId={networkId}
        targetNetworkId={getNetworkFriendlyId(chain)}
      />
    );
  }

  const depositedSingleToken = new BigNumber(
    depositedUsdNumber /
      (fetchPrice({ id: pool.assets.length > 1 ? pool.assets[1] : pool.assets[0] }) || 1)
  );

  const singleAssetRequire = require.context('images/single-assets', false, /\.(svg|webp|png)$/);
  const singleAssets = Object.fromEntries(
    singleAssetRequire.keys().map(path => [path.substring(2, path.lastIndexOf('.')), path])
  );

  return (
    <>
      <Helmet>
        <title>
          {getPageMeta('Vault-Meta-Title', {
            vaultName: pool.name,
            vaultDescription: pool.tokenDescription,
          })}
        </title>
        <meta
          property="og:title"
          content={getPageMeta('Vault-Meta-Title', {
            vaultName: pool.name,
            vaultDescription: pool.tokenDescription,
          })}
        />
      </Helmet>

      <VideoGuide />

      <HomeLink />

      {vaultId === 'cake-cakev2' ? <CakeV2Banner /> : ''}
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={3} className={classes.vaultContainer}>
          <h1>{pool.name} Vault</h1>
          <h3>({pool.assets.length > 1 ? pool.assets[1] : pool.assets[0]} Staking)</h3>

          <Grid container className={classes.subcontainerstats}>
            <Grid item xs={4}>
              <ApyStats
                apy={apy}
                launchpoolApr={launchpoolApr}
                isLoading={!fetchApysDone}
                itemClasses={`${classes.item} ${classes.itemStats}`}
                itemInnerClasses={classes.itemInner}
                showOnly="daily"
                depositedUsd={deposited * pool.oraclePrice}
              />
            </Grid>
            <Grid item xs={4}>
              <ApyStats
                apy={apy}
                launchpoolApr={launchpoolApr}
                isLoading={!fetchApysDone}
                itemClasses={`${classes.item} ${classes.itemStats}`}
                itemInnerClasses={classes.itemInner}
                showOnly="yearly"
              />
            </Grid>
            <Grid item xs={4}>
              <LabeledStat
                value={formatTvl(pool.tvl, pool.oraclePrice)}
                label={t('Vault-TVL')}
                isLoading={!fetchVaultsDataDone}
                className={classes.itemInner}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={7} className={classes.walletContainer}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={5} md={5} className={classes.subcontainerwallet}>
              <h3>In my wallet</h3>
              <div>
                <h2>
                  {formatDecimalsRounded(balanceSingle)}{' '}
                  {pool.assets.length > 1 ? pool.assets[1] : pool.assets[0]}
                </h2>
              </div>
              {balanceSingle > 0 && (
                <div>
                  <h2>(~{balanceUsd} USD)</h2>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.subcontainerdeposit}>
              <h3>Deposited with PegHub</h3>
              <div>
                <h2>
                  {/*formatDecimalsRounded(deposited)}{' '*/}
                  {depositedSingleToken ? formatDecimalsRounded(depositedSingleToken) : '-'}{' '}
                  {pool.assets.length > 1 ? pool.assets[1] : pool.assets[0]}
                </h2>
              </div>
              {deposited > 0 && (
                <div>
                  <h2>(~{depositedUsd} USD)</h2>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.manageContainer}>
        <div className={classes.managesubcontainerwrapper}>
          <div className={classes.subcontainermanage}>
            <div>
              <Button
                className={`${classes.showDetailButton} ${
                  activeTab === 'deposit'
                    ? classes.showDetailButtonContained
                    : classes.showDetailButtonOutlined
                }`}
                style={{
                  padding: '5px',
                  paddingLeft: '7px',
                  paddingRight: '7px',
                  fontSize: '16px',
                  borderTopLeftRadius: '25px',
                }}
                onClick={() => setActiveTab('deposit')}
              >
                Deposit
              </Button>
              <Button
                className={`${classes.showDetailButton} ${
                  activeTab === 'withdraw'
                    ? classes.showDetailButtonContained
                    : classes.showDetailButtonOutlined
                }`}
                style={{
                  padding: '5px',
                  paddingLeft: '7px',
                  paddingRight: '7px',
                  fontSize: '16px',
                  borderTopRightRadius: '25px',
                }}
                onClick={() => setActiveTab('withdraw')}
              >
                Withdraw
              </Button>
            </div>

            <div className={classes.subcontainermanageinner}>
              <PoolActions
                pool={pool}
                balanceSingle={balanceSingle}
                sharesBalance={sharesBalance}
                only={activeTab}
                onDepositSettingsChange={depositSettings => {
                  const newDepositPrice =
                    fetchPrice({
                      id: depositSettings.token.wrappedSymbol
                        ? depositSettings.token.wrappedSymbol
                        : depositSettings.token.symbol,
                    }) ||
                    fetchPrice({ id: depositSettings.token.symbol }) ||
                    1;
                  const newD = Number(Number(depositSettings.input * newDepositPrice).toFixed(2));
                  setInitialDeposit(Number(Number(depositedUsdNumber + newD).toFixed(2)));
                }}
                onWithdrawSettingsChange={withdrawSettings => {
                  // Currently the user can only withdraw in the pool asset
                  // so newWithdrawPrice is the same as depositedPrice
                  const newWithdrawPrice = Number(pool.oraclePrice.toFixed(12));
                  const w = Number(Number(withdrawSettings.input * newWithdrawPrice).toFixed(2));
                  setInitialDeposit(Number(Number(depositedUsdNumber - w).toFixed(2)));
                }}
              />
            </div>
          </div>
          <div className={classes.subcontainercalculate}>
            <h2>Use our calculator to see how much your assets could grow</h2>

            <Calculate
              pool={pool}
              apy={apy}
              initialDeposit={initialDeposit}
              setInitialDeposit={setInitialDeposit}
            />
          </div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <a href={pool.buyTokenUrl} target="_blank" className={classes.buyTokenLink}>
            Buy Token
          </a>
        </div>
      </div>
    </>
  );
};

export default PoolDetails;
