const styles = theme => ({
  toolbar: {
    padding: '15px 20px',
  },
  showDetailLeft: {
    fontSize: '1rem',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    fontWeight: '500',
  },
  zapFormControl: {
    display: 'inline-block',
    minWidth: 'auto',
    marginTop: '-5px',
  },
  zapSelect: {
    borderRadius: '5px',
    border: '1px solid rgba(255, 255, 255, 0.23)',
    marginLeft: '2px',
    paddingLeft: '10px',
    fontWeight: '500',
    '& > div': {
      padding: '6px 0',
    },
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
  },
  amountContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      textAlign: 'left',
    },
  },
  amountLabel: {
    fontSize: '1rem',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    fontWeight: '500',
  },
  amountInput: {
    display: 'inline-block',
    minWidth: 'auto',
    marginTop: '-5px',
    marginLeft: '10px',
    width: '100px',
    borderRadius: '5px',
    '& input': {
      padding: '6px 0',
    },
  },
  periodContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  chartContainer: {
    marginTop: '20px',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .peghub-tooltip': {
      color: '#fff',
      backgroundColor: '#000',
      padding: '2px 20px !important',
      borderRadius: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

export default styles;
