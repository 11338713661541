import { hexToRgb, primaryColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  noWalletButtonCon: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  noWalletButton: {
    margin: '20px 0',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: primaryColor[0],
    '& .MuiButton-label': {
      color: 'white',
    },
  },
  vaultContainer: {
    position: 'relative',
    padding: '24px',
    // backgroundColor: 'rgba(' + hexToRgb('#000') + ', 0.6)',
    borderRadius: '25px',
    marginBottom: '25px',
    '& h1': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& h3': {
      marginTop: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '150px',
    },
  },
  walletContainer: {
    position: 'relative',
    padding: '24px',
    // backgroundColor: 'rgba(' + hexToRgb('#000') + ', 0.6)',
    background: theme.palette.background.primary,
    borderRadius: '25px',
    marginBottom: '25px',
  },
  manageContainer: {
    position: 'relative',
    padding: '24px',
    // backgroundColor: 'rgba(' + hexToRgb('#000') + ', 0.6)',
    background: theme.palette.background.primary,
    borderRadius: '25px',
    marginBottom: '25px',
  },
  managesubcontainerwrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  subcontainermanage: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  subcontainermanageinner: {
    background: '#402533',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: 0,
    paddingBottom: '10px',
    height: 'calc(100% - 37px)',
    borderBottomLeftRadius: '25px',
    borderBottomRightRadius: '25px',
  },
  subcontainercalculate: {
    textAlign: 'center',
    borderRadius: '25px',
    padding: '10px',
    background: '#402533',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      width: '100%',
    },
  },
  subcontainerwallet: {
    borderRadius: '25px',
    textAlign: 'center',
    background: '#402533',
    padding: '20px',
    '& h2': {
      fontSize: '15px',
      marginTop: '0',
      marginBottom: '10px',
    },
    '& h3': {
      marginTop: '5px',
    },
  },
  subcontainerdeposit: {
    borderRadius: '25px',
    textAlign: 'center',
    background: '#402533',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
    '& h2': {
      fontSize: '15px',
      marginTop: '0',
      marginBottom: '10px',
    },
    '& h3': {
      marginTop: '5px',
    },
  },
  subcontainerstats: {
    padding: '10px',
    paddingTop: '15px',
    borderRadius: '25px',
    position: 'absolute',
    bottom: '2px',
    left: '3px',
    background: theme.palette.background.primary,
    width: '94%',
  },
  subcontainerseparator: {
    marginTop: '0',
  },
  heading: {
    color: theme.palette.primary.main,
  },
  summary: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  statusIcon: {
    marginRight: '.5rem',
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '10px',
    color: theme.palette.text.primary,
    marginLeft: '4px',
    'flex-shrink': 0,
    width: '45px',
    height: '45px',
    '& .MuiAvatarGroup-avatar': {
      border: 'none',
      width: '65%',
      height: '65%',
      '&:first-child': {
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '&:last-child': {
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    },
  },
  status: {
    padding: '24px',
    marginBottom: '8px',
    background: pool =>
      pool.status === 'eol'
        ? theme.palette.background.retired
        : pool.depositsPaused
        ? theme.palette.background.paused
        : theme.palette.background.primary,
  },
  description: {
    padding: '8px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  itemTitle: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  itemBalances: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  itemStats: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  itemButtons: {
    alignItems: 'end',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: '100%',
      paddingTop: '15px',
      paddingBottom: '20px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: '12.5%',
      maxWidth: '12.5%',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'right',
    },
  },
  itemButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  itemInner: {
    textAlign: 'center',
  },
  showDetailButton: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 'bold',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    width: '50%',
    padding: '2px',
    '& img': {
      height: '14px',
      verticalAlign: 'middle',
    },
    '& span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  showDetailButtonOutlined: {
    color: '#fff',
    backgroundColor: 'transparent',
    '& .MuiButton-label': {
      color: 'white',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3c4858',
    },
  },
  showDetailButtonContained: {
    color: '#fff',
    backgroundColor: '#402533',
    '& .MuiButton-label': {
      color: 'white',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#a81131',
    },
  },
  buyTokenLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    '&:focus': {
      color: '#000',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  error: {
    color: 'red',
  },
  loading: {
    color: theme.palette.primary.main,
  },
});

export default styles;
