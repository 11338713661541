import React, { useEffect, useState } from 'react';

import VideoGuide from '../../components/VideoGuide/VideoGuide.js';
import { makeStyles } from '@material-ui/core/styles';
import homeStyles from './homeStyles.js';
import HeroCoins from './components/HeroCoins/HeroCoins.js';
import { Link } from 'react-router-dom';
import Calculator from './components/Calculator/Calculator.js';
import { useConnectWallet } from './redux/connectWallet.js';
import { useFetchVaultsData } from '../vault/redux/fetchVaultsData.js';
import { useFetchApys } from '../vault/redux/fetchApys.js';
import { formatGlobalTvl } from '../helpers/format.js';
import { fetchPrice } from '../../features/web3/index.js';
import TVLLoader from '../../components/StatsBar/TVLLoader/TVLLoader.js';
import BigNumber from 'bignumber.js';
import { byDecimals } from '../helpers/bignumber.js';
import { useFetchBalances } from '../vault/redux/fetchBalances.js';
import { usePoolsTvl, useUserTvl } from '../vault/hooks/usePoolsTvl.js';
import { useFetchBifibuyback } from '../vault/redux/fetchBifiBuyback.js';
import { phubfeedistributorABI } from '../configure/abi.js';
import Web3 from 'web3';

const useStyles = makeStyles(homeStyles);

const FETCH_INTERVAL_MS = 15 * 1000;

export default function HomePage() {
  const classes = useStyles();

  const { web3, address, connected } = useConnectWallet();

  const { pools, fetchVaultsData, fetchVaultsDataPending, fetchVaultsDataDone } =
    useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const { bifibuyback, fetchBifibuyback, fetchBifibuybackDone } = useFetchBifibuyback();
  const { poolsTvl } = usePoolsTvl(pools);
  const { userTvl } = useUserTvl(pools, tokens);

  useEffect(() => {
    fetchApys();
    const id = setInterval(fetchApys, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchApys]);

  useEffect(() => {
    fetchBifibuyback();
    const id = setInterval(fetchBifibuyback, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchBifibuyback]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
      }
      if (!fetchVaultsDataPending) {
        fetchVaultsData({ web3, pools });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  const activePools = pools.filter(pool => pool.status === 'active');
  const activePoolCount = activePools.length;
  let avgApy = 0;
  let dailyYield = 0;
  let monthlyYield = 0;
  let yearlyYield = 0;
  if (fetchVaultsDataDone && fetchBalancesDone && fetchApysDone) {
    activePools.forEach(pool => {
      const sharesBalance = tokens[pool.earnedToken].launchpoolTokenBalance
        ? new BigNumber.sum(
            tokens[pool.earnedToken].launchpoolTokenBalance,
            tokens[pool.earnedToken].tokenBalance
          )
        : new BigNumber(tokens[pool.earnedToken].tokenBalance);
      const deposited = byDecimals(
        sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
        pool.tokenDecimals
      );
      const depositedUsd = deposited > 0 && fetchVaultsDataDone ? deposited * pool.oraclePrice : 0;

      const curApy = apys[pool.id] ? apys[pool.id].totalApy : 0;

      const curApyDaily = yearlyToDaily(curApy);
      const curApyMonthly = yearlyToMonthly(curApy);

      dailyYield = dailyYield + depositedUsd * curApyDaily;
      monthlyYield = monthlyYield + depositedUsd * curApyMonthly;
      yearlyYield = yearlyYield + depositedUsd * curApy;
      avgApy = avgApy + curApy;
    });
    avgApy = avgApy / activePoolCount;
  }

  const chainBifibuyback =
    bifibuyback && bifibuyback['bsc'] ? bifibuyback['bsc'].buybackUsdAmount : undefined;

  const [phubPrice, setPhubPrice] = useState();
  const [phubBoughtBack, setPhubBoughtBack] = useState();

  useEffect(() => {
    const fetch = () => {
      setPhubPrice(fetchPrice({ id: 'PHUB' }));
    };
    setTimeout(fetch, 1000);

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrice]);

  useEffect(() => {
    const getRpcContract = (address, abi) => {
      const url = 'https://rpc.ankr.com/bsc';
      const provider = new Web3(new Web3.providers.HttpProvider(url));

      return new provider.eth.Contract(abi, address);
    };

    const fetch = async () => {
      const contract = getRpcContract(
        '0xA9deF29dB63ef56E1AEE4a695109911DDEd8C644',
        phubfeedistributorABI
      );

      if (!contract) {
        return 0;
      }

      var balance = await contract.methods.totalBought().call({});
      const digits = 18;
      var adjustedBalance = balance / Math.pow(10, digits);
      if (adjustedBalance < 0) {
        adjustedBalance = 0;
      }

      setPhubBoughtBack(adjustedBalance);
    };
    setTimeout(fetch, 1000);

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [calculatorSummary, setCalculatorSummary] = useState('');

  return (
    <>
      {/*<Disclaimer />*/}
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          Earning high yields has never been easier! Pick your favourite crypto below and get
          started now.
        </div>
        {/*<div className={classes.separator}>&nbsp;</div>*/}
      </div>

      <VideoGuide />

      <HeroCoins pools={pools} apys={apys} />

      <div className={classes.grid} style={{ marginTop: '30px' }}>
        <div className={classes.gridLargeCol}>
          {fetchApysDone && (
            <Calculator
              pools={pools}
              apys={apys}
              setSummary={summary => setCalculatorSummary(summary)}
            />
          )}

          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>{calculatorSummary}</p>
            <p>
              Learn about our vaults, how yields are generated, and much more in our
              <a
                href="https://www.peghub.com/frequently-asked-questions"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                FAQ{' '}
              </a>
            </p>
            <p>*APY is variable</p>
          </div>
        </div>
        <div className={classes.gridSmallCol}>
          <p style={{ marginTop: 0, marginBottom: '20px' }}>
            <Link
              to={`/vaults`}
              className={`${classes.showDetailButton} ${classes.showDetailButtonContained}`}
              style={{ width: '100%', textAlign: 'center' }}
            >
              Start Earning Now
            </Link>
          </p>

          <div className={`${classes.sidebarSection} ${classes.sidebarSectionFirst}`}>
            The Peg Hub Ecosystem Currently has{' '}
            <span className={classes.highlightRed}>
              {fetchVaultsDataDone && poolsTvl > 0 ? (
                formatGlobalTvl(poolsTvl)
              ) : (
                <TVLLoader className={classes.titleLoader} />
              )}
            </span>{' '}
            in assets between {activePoolCount} vaults and 3 protocols.
          </div>
          <div className={classes.sidebarSection}>
            <h4>
              PHUB Price{' '}
              <span className={classes.floatRight}>
                {phubPrice ? formatGlobalTvl(phubPrice) : '-'}
              </span>
            </h4>
            <p>
              Today we bought back{' '}
              <span className={classes.highlightRed}>
                {fetchBifibuybackDone && chainBifibuyback && (
                  <>{formatGlobalTvl(chainBifibuyback)}</>
                )}
              </span>{' '}
              of PHUB and gave it back to our PHUB holders. Total of{' '}
              <span className={classes.highlightRed}>
                {phubBoughtBack ? Number(phubBoughtBack).toFixed(2) : '-'}
              </span>{' '}
              PHUB tokens have been bought back. Only{' '}
              <span className={classes.highlightRed}>10,000</span> PHUB tokens will ever exist.
            </p>
          </div>
          <div className={classes.sidebarSection}>
            <h4>
              BALANCE{' '}
              <span className={classes.floatRight}>
                {fetchVaultsDataDone && fetchBalancesDone ? (
                  formatGlobalTvl(userTvl)
                ) : (
                  <>
                    {connected ? <TVLLoader className={classes.titleLoader} /> : <>Not Connected</>}
                  </>
                )}
              </span>
            </h4>
            <p>
              Daily Earnings:{' '}
              <span className={classes.floatRight}>
                {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                  formatGlobalTvl(dailyYield)
                ) : (
                  <>
                    {connected ? <TVLLoader className={classes.titleLoader} /> : <>Not Connected</>}
                  </>
                )}
              </span>
            </p>
            <p>
              Monthly Earnings:{' '}
              <span className={classes.floatRight}>
                {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                  formatGlobalTvl(monthlyYield)
                ) : (
                  <>
                    {connected ? <TVLLoader className={classes.titleLoader} /> : <>Not Connected</>}
                  </>
                )}
              </span>
            </p>
            <p>
              Yearly Earnings:{' '}
              <span className={classes.floatRight}>
                {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                  formatGlobalTvl(yearlyYield)
                ) : (
                  <>
                    {connected ? <TVLLoader className={classes.titleLoader} /> : <>Not Connected</>}
                  </>
                )}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.grid}>
        <div
          className={classes.gridLargeCol}
          style={{ textAlign: 'center', backgroundColor: 'transparent' }}
        >
          <p>
            <Link
              to={`/vaults`}
              className={`${classes.showDetailButton} ${classes.showDetailButtonContained}`}
            >
              Start Earning Now
            </Link>
          </p>
        </div>
        <div className={classes.gridSmallCol} style={{ textAlign: 'center', paddingTop: '20px' }}>
          &nbsp;
        </div>
      </div>
    </>
  );
}

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const yearlyToMonthly = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 12) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};
