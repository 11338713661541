import React, { useCallback, useContext } from 'react';
import transakSDK from '@transak/transak-sdk';
import { useConnectWallet } from '../../features/home/redux/connectWallet.js';

export const Transak = props => {
  const { address, networkId } = useConnectWallet();
  const ktransak = {
    ApiKey: '11eca9d2-ee0e-46d9-9ae4-324cde64c371',
    Environment: 'PRODUCTION',
  };

  console.log();
  const init = useCallback(() => {
    if (ktransak) {
      let transak = new transakSDK({
        apiKey: ktransak.ApiKey, // Your API Key (Required)
        environment: ktransak.Environment, // STAGING/PRODUCTION (Required)
        defaultCryptoCurrency: networkId === 43114 ? 'AVAX' : 'BNB',
        defaultNetwork: networkId === 43114 ? 'avalanche' : 'bsc',
        walletAddress: address, // Your customer wallet address
        themeColor: 'ffd918', // App theme color in hex
        email: '', // Your customer email address (Optional)
        redirectURL: '',
        hostURL: window.location.origin, // Required field
        widgetHeight: '550px',
        widgetWidth: '450px',
      });

      transak.init();

      // To get all the events
      transak.on(transak.ALL_EVENTS, data => {
        // console.log(data);
      });

      // This will trigger when the user closed the widget
      transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, orderData => {
        transak.close();
      });

      // This will trigger when the user marks payment is made.
      transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, orderData => {
        // console.log(orderData);
        transak.close();
      });
    }
  }, [ktransak]);

  return (
    <div
      className={'transak cursor-pointer' + (props.className ? ` ${props.className}` : '')}
      onClick={init}
    >
      {props.children}
    </div>
  );
};

export default Transak;
