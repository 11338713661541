import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Grid from '@material-ui/core/Grid';

import TVLLoader from './TVLLoader/TVLLoader';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useFetchBalances, useFetchVaultsData, useFetchApys } from '../../redux/hooks';
import VisiblePools from '../VisiblePools/VisiblePools';
import styles from './styles';
import { usePoolsTvl, useUserTvl } from '../../hooks/usePoolsTvl';
import { formatGlobalTvl, formatTvl } from 'features/helpers/format';
import { useFetchBifibuyback } from 'features/vault/redux/fetchBifiBuyback';
import { getNetworkFriendlyName } from '../../../helpers/getNetworkData';
import { byDecimals, isNaN } from '../../../helpers/bignumber.js';
import BigNumber from 'bignumber.js';
import Hidden from '@material-ui/core/Hidden';
import Disclaimer from '../../../../components/Disclaimer/Disclaimer';

const FETCH_INTERVAL_MS = 15 * 1000;

const useStyles = makeStyles(styles);

export default function Pools() {
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { pools, fetchVaultsData, fetchVaultsDataPending, fetchVaultsDataDone } =
    useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const { bifibuyback, fetchBifibuyback, fetchBifibuybackDone } = useFetchBifibuyback();
  const { poolsTvl } = usePoolsTvl(pools);
  const { userTvl } = useUserTvl(pools, tokens);
  const classes = useStyles();

  useEffect(() => {
    fetchApys();
    const id = setInterval(fetchApys, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchApys]);

  useEffect(() => {
    fetchBifibuyback();
    const id = setInterval(fetchBifibuyback, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchBifibuyback]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
      }
      if (!fetchVaultsDataPending) {
        fetchVaultsData({ web3, pools });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  // const chainNameLowercase = getNetworkFriendlyName().toLowerCase();
  // const chainBifibuyback =
  //   fetchBifibuybackDone && chainNameLowercase in bifibuyback
  //     ? bifibuyback[chainNameLowercase].buybackUsdAmount
  //     : undefined;

  const activePools = pools
    .filter(pool => pool.status === 'active')
    .sort((a, b) => {
      const tvlA = Number(BigNumber(a.tvl).times(a.oraclePrice).toFixed(2));
      const tvlB = Number(BigNumber(b.tvl).times(b.oraclePrice).toFixed(2));
      if (a.id === 'bomb-phub') {
        return 1;
      }
      if (b.id === 'bomb-phub') {
        return -1;
      }
      return tvlB - tvlA;
    });
  const activePoolCount = activePools.length;

  let avgApy = 0;
  let dailyYield = 0;
  let monthlyYield = 0;
  if (fetchVaultsDataDone && fetchBalancesDone && fetchApysDone) {
    activePools.forEach(pool => {
      const sharesBalance = tokens[pool.earnedToken].launchpoolTokenBalance
        ? new BigNumber.sum(
            tokens[pool.earnedToken].launchpoolTokenBalance,
            tokens[pool.earnedToken].tokenBalance
          )
        : new BigNumber(tokens[pool.earnedToken].tokenBalance);
      const deposited = byDecimals(
        sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
        pool.tokenDecimals
      );
      const depositedUsd = deposited > 0 && fetchVaultsDataDone ? deposited * pool.oraclePrice : 0;

      const curApy = apys[pool.id] ? apys[pool.id].totalApy : 0;

      const curApyDaily = yearlyToDaily(curApy);
      const curApyMonthly = yearlyToMonthly(curApy);

      dailyYield = dailyYield + depositedUsd * curApyDaily;
      monthlyYield = monthlyYield + depositedUsd * curApyMonthly;
      avgApy = avgApy + curApy;
    });
    avgApy = avgApy / activePoolCount;
  }

  return (
    <Grid container className={classes.container}>
      <VisiblePools
        pools={activePools}
        apys={apys}
        tokens={tokens}
        fetchBalancesDone={fetchBalancesDone}
        fetchApysDone={fetchApysDone}
        fetchVaultsDataDone={fetchVaultsDataDone}
      />

      {/*<Disclaimer />*/}
    </Grid>
  );
}

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const yearlyToMonthly = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 12) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};
