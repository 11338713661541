import {
  hexToRgb,
  primaryColor,
  whiteColor,
} from '../../../../assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  details: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.primary,
    // backgroundColor: 'rgba(' + hexToRgb('#0f1116') + ', 0.64)',
    // border: '1px solid rgba(' + hexToRgb('#373234') + ', 0.39)',
    padding: '0px 35px',
    paddingLeft: '31px',
    borderRadius: '25px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2px',
      paddingRight: '2px',
    },
  },
  detailsPaused: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paused,
  },
  detailsRetired: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.retired,
  },
  mobilePadding: {
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  itemTitle: {
    borderRight: '1px solid #dcdedf',
    padding: '15px 10px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '25%',
      maxWidth: '25%',
      borderRight: 'none',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '20%',
      maxWidth: '20%',
    },
  },
  itemBalances: {
    borderRight: '1px solid #dcdedf',
    padding: '15px 10px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '25%',
      maxWidth: '25%',
      borderRight: 'none',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '20%',
      maxWidth: '20%',
    },
  },
  itemStats: {
    borderRight: '1px solid #dcdedf',
    padding: '15px 10px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '25%',
      maxWidth: '25%',
      borderRight: 'none',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '20%',
      maxWidth: '20%',
    },
  },
  itemButtons: {
    alignItems: 'end',
    flexBasis: '20%',
    maxWidth: '20%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%',
      height: '60px',
      borderRight: 'none',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '20%',
      maxWidth: '20%',
    },
  },
  itemButtonWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  itemInner: {
    textAlign: 'center',
  },
  showDetailButton: {
    margin: '3px 0px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '110px',
    background: 'transparent',
    border: '1px solid #4b4f5b',
    padding: '2px',
    '& img': {
      height: '14px',
      verticalAlign: 'middle',
    },
    '& span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: 'transparent',
      border: '1px solid #fff',
    },
  },
  showDetailButtonOutlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2px',
    color: primaryColor[0],
    '&:hover': {
      '& .MuiButton-label': {
        color: 'white',
      },
    },
    '& .MuiTouchRipple-root span': {
      backgroundColor: primaryColor[0],
    },
  },
  showDetailButtonContained: {
    color: '#fff',
    backgroundColor: theme.palette.background.red,
    border: '1px solid ' + theme.palette.background.red,
    padding: '10px 10px',
    '& .MuiButton-label': {
      color: 'white',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#a81131',
      border: '1px solid #a81131',
    },
  },
  expandArrow: {
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    '& span': {
      transform: 'rotate(90deg)',
      display: 'inline-block',
    },
  },
});

export default styles;
