import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import TVLLoader from './TVLLoader/TVLLoader';
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchBalances,
  useFetchVaultsData,
  useFetchApys,
} from '../../features/vault/redux/hooks';
import styles from './styles';
import { usePoolsTvl, useUserTvl } from '../../features/vault/hooks/usePoolsTvl';
import { formatGlobalTvl } from 'features/helpers/format';
import { useFetchBifibuyback } from 'features/vault/redux/fetchBifiBuyback';
import { getNetworkFriendlyName } from '../../features/helpers/getNetworkData';
import { byDecimals } from '../../features/helpers/bignumber.js';
import BigNumber from 'bignumber.js';
import Hidden from '@material-ui/core/Hidden';

const FETCH_INTERVAL_MS = 15 * 1000;

const useStyles = makeStyles(styles);

export default function StatsBar() {
  const { t } = useTranslation();
  const { web3, address, connected } = useConnectWallet();
  const { pools, fetchVaultsData, fetchVaultsDataPending, fetchVaultsDataDone } =
    useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const { bifibuyback, fetchBifibuyback, fetchBifibuybackDone } = useFetchBifibuyback();
  const { poolsTvl } = usePoolsTvl(pools);
  const { userTvl } = useUserTvl(pools, tokens);
  const classes = useStyles();

  useEffect(() => {
    fetchApys();
    const id = setInterval(fetchApys, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchApys]);

  useEffect(() => {
    fetchBifibuyback();
    const id = setInterval(fetchBifibuyback, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchBifibuyback]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
      }
      if (!fetchVaultsDataPending) {
        fetchVaultsData({ web3, pools });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  const chainNameLowercase = getNetworkFriendlyName().toLowerCase();
  const chainBifibuyback =
    fetchBifibuybackDone && chainNameLowercase in bifibuyback
      ? bifibuyback[chainNameLowercase].buybackUsdAmount
      : undefined;

  const activePools = pools.filter(pool => pool.status === 'active');
  const activePoolCount = activePools.length;

  let avgApy = 0;
  let dailyYield = 0;
  let monthlyYield = 0;
  if (fetchVaultsDataDone && fetchBalancesDone && fetchApysDone) {
    activePools.forEach(pool => {
      const sharesBalance = tokens[pool.earnedToken].launchpoolTokenBalance
        ? new BigNumber.sum(
            tokens[pool.earnedToken].launchpoolTokenBalance,
            tokens[pool.earnedToken].tokenBalance
          )
        : new BigNumber(tokens[pool.earnedToken].tokenBalance);
      const deposited = byDecimals(
        sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
        pool.tokenDecimals
      );
      const depositedUsd = deposited > 0 && fetchVaultsDataDone ? deposited * pool.oraclePrice : 0;

      const curApy = apys[pool.id].totalApy;

      const curApyDaily = yearlyToDaily(curApy);
      const curApyMonthly = yearlyToMonthly(curApy);

      dailyYield = dailyYield + depositedUsd * curApyDaily;
      monthlyYield = monthlyYield + depositedUsd * curApyMonthly;
      avgApy = avgApy + curApy;
    });
    avgApy = avgApy / activePoolCount;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} xl={5}>
        {/* <h1 className={classes.title}>{t('Vault-Network')}</h1> */}
        {/* <NetworksToggle /> */}
        {/*{fetchVaultsDataDone && activePoolCount && (*/}
        {/*  <>*/}
        {/*    <h2 className={classes.title}> {`${activePoolCount} ${t('Vault-MainTitle')}`}</h2>*/}
        {/*  </>*/}
        {/*)}*/}
        <Grid container className={classes.subcontainer} justifyContent="flex-start">
          <Hidden mdDown>
            <Grid item xs={2} xl={1}>
              <img alt="Deposits" src={require(`images/deposited_outline.png`)} />
            </Grid>
          </Hidden>
          <Grid item xs={4} md={3}>
            {connected ? (
              <>
                <span className={classes.title}>{t('Vault-Deposited')}</span>
                <span className={classes.text}>
                  {fetchVaultsDataDone && fetchBalancesDone ? (
                    formatGlobalTvl(userTvl)
                  ) : (
                    <TVLLoader className={classes.titleLoader} />
                  )}
                </span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </Grid>
          <Grid item xs={4} md={3}>
            {connected ? (
              <>
                <span className={classes.title}>Daily yield</span>
                <span className={classes.text}>
                  {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                    formatGlobalTvl(dailyYield)
                  ) : (
                    <TVLLoader className={classes.titleLoader} />
                  )}
                </span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </Grid>
          <Grid item xs={4} md={3}>
            {connected ? (
              <>
                <span className={classes.title}>Monthly yield</span>
                <span className={classes.text}>
                  {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                    formatGlobalTvl(monthlyYield)
                  ) : (
                    <TVLLoader className={classes.titleLoader} />
                  )}
                </span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Hidden lgDown>
        <Grid item xs={2}>
          <Grid container className={classes.subcontainer}>
            <Grid item xs={12}>
              <span className={classes.title}>&nbsp;</span>
              <span className={classes.text}>&nbsp;</span>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={6} xl={5}>
        <Grid container className={classes.subcontainer} justifyContent="flex-end">
          <Grid item xs={4} md={3}>
            {connected ? (
              <>
                <span className={classes.title}>AVG APY</span>
                <span className={classes.text}>
                  {fetchVaultsDataDone && fetchBalancesDone && fetchApysDone ? (
                    <>{avgApy ? avgApy.toFixed(2) : '-'}%</>
                  ) : (
                    <TVLLoader className={classes.titleLoader} />
                  )}
                </span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </Grid>
          <Grid item xs={4} md={3}>
            <span className={classes.title}>TVL</span>
            <span className={classes.text}>
              {fetchVaultsDataDone && poolsTvl > 0 ? (
                formatGlobalTvl(poolsTvl)
              ) : (
                <TVLLoader className={classes.titleLoader} />
              )}
            </span>
          </Grid>
          <Grid item xs={4} md={3}>
            <span className={classes.title}>Daily buyback</span>
            <span className={classes.text}>
              {fetchBifibuybackDone && chainBifibuyback && <>{formatGlobalTvl(chainBifibuyback)}</>}
            </span>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1}>
              <img alt="Buybacks" src={require(`images/phub_outline.png`)} />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
}

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const yearlyToMonthly = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 12) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};
