import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const VideoGuide = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <>
      <div className={classes.titleContainer}>
        <div
          className={classes.title}
          onClick={() => {
            setShowVideo(!showVideo);
          }}
        >
          Need help? Get started in 3 easy steps!{' '}
          <i className={`${classes.arrow} ${showVideo ? classes.up : classes.down}`}></i>
        </div>
      </div>

      <div
        className={`${classes.videoWrapper} ${
          showVideo ? classes.videoWrapperVisible : classes.videoWrapperInvisible
        }`}
      >
        <div className={classes.videoContainer}>
          <div className={classes.videoStep}>
            <h1>Step One</h1>
            <p>How To Download and Set Up MetaMask on Your Browser</p>
            {showVideo && (
              <iframe
                src="https://www.youtube.com/embed/B48QWdavbr0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <div className={classes.videoStep}>
            <h1>Step Two</h1>
            <p>How To Purchase Cryptocurrency With Your Credit Card</p>
            {showVideo && (
              <iframe
                src="https://www.youtube.com/embed/qyysoepuvzs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <div className={classes.videoStep}>
            <h1>Step Three</h1>
            <p>How To Deposit into PegHub Autocompounding Vaults</p>
            {showVideo && (
              <iframe
                src="https://www.youtube.com/embed/4QkqHkBlPkk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoGuide;
