import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import CustomOutlinedInput from '../../../../components/CustomOutlinedInput/CustomOutlinedInput.js';
import { fetchPrice } from '../../../web3/index.js';
import BigNumber from 'bignumber.js';
import { apyValues } from '../PoolSummary/ApyStats/ApyStats.js';
import { getNetworkCoin } from '../../../helpers/getNetworkData.js';

const nativeCoin = getNetworkCoin();

const useStyles = makeStyles(styles);

const compoundInterest = (p, t, r, n) => {
  const amount = p * Math.pow(1 + r / n, n * t);
  return amount - p;
};

const Calculate = ({ pool, apy, initialDeposit, setInitialDeposit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [estimatedOutput, setEstimatedOutput] = React.useState(0);
  const [values] = apyValues(apy, null, false);
  const [userTypedZero, setUserTypedZero] = React.useState(false);

  const handleInputAmountChange = input => {
    if (input === '') {
      setUserTypedZero(true);
    } else {
      setUserTypedZero(false);
    }

    let amount = new BigNumber(input);
    if (amount.isNaN()) amount = new BigNumber(0);
    amount = amount.decimalPlaces(2);

    setInitialDeposit(amount.isEqualTo(input) ? input : amount.toFormat());
  };

  useEffect(() => {
    const amount = new BigNumber(initialDeposit);
    const isNativeCoin =
      pool.assets[pool.assets.length - 1] === nativeCoin.symbol ||
      pool.assets[pool.assets.length - 1] === nativeCoin.wrappedSymbol;
    const estimatedOutput = amount
      .dividedBy(
        fetchPrice({
          id: isNativeCoin ? nativeCoin.wrappedSymbol : pool.assets[pool.assets.length - 1],
        }) ||
          fetchPrice({ id: isNativeCoin ? nativeCoin.symbol : pool.assets[pool.assets.length - 1] })
      )
      .decimalPlaces(2);
    setEstimatedOutput(estimatedOutput.toFormat());
  }, [initialDeposit]);

  return (
    <div className={classes.container}>
      <div className={`${classes.inputGroup} ${classes.inputGroupWithBorder}`}>
        <div className="label">Deposit ($)</div>

        <CustomOutlinedInput
          value={initialDeposit === '0' && userTypedZero ? '' : initialDeposit}
          onChange={event =>
            handleInputAmountChange(event.target.value.replace(/[,]+/, '').replace(/[^0-9\.]+/, ''))
          }
          fullWidth
        />
      </div>

      <div className={classes.inputGroup}>
        <div className="label">Estimated {pool.assets[pool.assets.length - 1]}</div>

        <CustomOutlinedInput value={estimatedOutput} disabled fullWidth />
      </div>

      <div className={classes.statsGroup}>
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>Projections</td>
            </tr>
            <tr>
              <td>1 month</td>
              <td>
                {'$'}
                {(
                  Number(initialDeposit) +
                  compoundInterest(Number(initialDeposit), 1, values.totalMonthly, 1)
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>3 months</td>
              <td>
                {'$'}
                {(
                  Number(initialDeposit) +
                  compoundInterest(Number(initialDeposit), 3, values.totalMonthly, 1)
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>6 months</td>
              <td>
                {'$'}
                {(
                  Number(initialDeposit) +
                  compoundInterest(Number(initialDeposit), 6, values.totalMonthly, 1)
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>1 year</td>
              <td>
                {'$'}
                {(
                  Number(initialDeposit) +
                  compoundInterest(Number(initialDeposit), 12, values.totalMonthly, 1)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calculate;
