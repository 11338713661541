import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { WbSunny, NightsStay } from '@material-ui/icons';
import Davatar from '@davatar/react';
import Button from 'components/CustomButtons/Button.js';
import { useTranslation } from 'react-i18next';
import { useENS } from 'hooks/useENS';
import { LanguageDropdown } from '../LanguageDropdown/LanguageDropdown';
import Transak from '../Transak/Transak';

import styles from './styles';

const useStyles = makeStyles(styles);

const HeaderLinks = ({
  connected,
  address,
  connectWallet,
  disconnectWallet,
  isNightMode,
  setNightMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [shortAddress, setShortAddress] = useState('');
  const { ensName } = useENS(address);

  useEffect(() => {
    if (!connected) {
      return;
    }

    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [address, connected]);

  return (
    <List className={classes.list + ' ' + classes.mlAuto}>
      {/*<Hidden smDown>
        <ListItem className={classes.listItem}>
          <IconButton onClick={setNightMode} className={classes.iconButton}>
            {isNightMode ? <WbSunny /> : <NightsStay />}
          </IconButton>
        </ListItem>
      </Hidden>
      <ListItem className={classes.listItem}>
        <LanguageDropdown navLinkClass={classes.navLink} />
      </ListItem>*/}
      <Hidden smDown>
        {renderLink('https://swap.peghub.com/swap', t('Swap'), null, classes)}
        {renderLink('https://vote.peghub.com', t('Vote'), null, classes)}
        {renderLink('https://www.peghub.com/blog', t('Blog'), null, classes)}
        <Transak>
          <a
            href="#"
            rel="noopener noreferrer"
            className={classes.link}
            style={{ marginLeft: '5px', marginRight: '5px', position: 'relative' }}
            onClick={e => {
              e.preventDefault();
              return false;
            }}
          >
            <span>Purchase Crypto</span>
            <img
              src={require(`../../images/visa-mastercard-logos.png`)}
              height={'20px'}
              style={{ position: 'absolute', bottom: '-11px', left: '54px' }}
            />
          </a>
        </Transak>
      </Hidden>
      <ListItem className={classes.listItem}>
        <Button
          disableElevation
          className={connected ? classes.walletDisplayConnected : classes.walletDisplay}
          onClick={connected ? disconnectWallet : connectWallet}
        >
          {connected ? (
            <>
              {/*<span style={{ marginRight: '6px' }}>*/}
              {/*  <Davatar size={25} address={address} />*/}
              {/*</span>*/}
              {ensName || shortAddress}
            </>
          ) : (
            <>{t('Connect')}</>
          )}
        </Button>
      </ListItem>
    </List>
  );
};

const renderLink = (url, label, icon, classes, color) => {
  return (
    <ListItem className={classes.listItem}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {icon && <i className={`fas fa-${icon} ${classes.icon}`} />}
        {color ? <span style={{ color: color }}>{label}</span> : <span>{label}</span>}
      </a>
    </ListItem>
  );
};

export default HeaderLinks;
