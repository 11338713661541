import { containerFluid, hexToRgb } from '../../assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  topTitleContainer: {
    maxWidth: '920px',
    padding: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
    borderRadius: '25px',
    backgroundColor: 'rgba(' + hexToRgb('#000') + ', 0.6)',
    zIndex: 100,
  },
  topTitle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '20px',
  },
  topTitleSeparator: {
    width: '50px',
    borderBottom: `4px solid ${theme.palette.text.red}`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  topTitleSocialIcons: {
    marginTop: '20px',
    textAlign: 'center',
    '& a': {
      display: 'inline-block',
      color: '#000',
      padding: '5px',
      fontSize: '32px',
      width: '50px',
      height: '50px',
      backgroundColor: theme.palette.background.red,
      borderRadius: '50%',
      margin: '0 10px',
    },
    '& i': {
      verticalAlign: 'middle',
    },
  },
  container: {
    ...containerFluid,
    minHeight: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    paddingLeft: '0',
    paddingRight: '0',
    flexWrap: 'nowrap',
    '@media (min-width: 1230px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  subcontainer: {
    backgroundColor: 'rgba(' + hexToRgb('#000') + ', 0.6)',
    padding: '10px',
    paddingLeft: '25px',
    paddingRight: '25px',
    zIndex: 100,
  },
  containerItem: {
    height: '35px',
  },
  logo: {
    marginRight: '22px',
    backgroundColor: 'transparent',
  },
  copyright: {
    marginTop: '5px',
    fontWeight: 300,
    textAlign: 'center',
    color: '#fff',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 900,
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  links: {
    marginTop: '5px',
  },
  link: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bubble: {
    position: 'absolute',
    bottom: '-90px',
    '@media (min-width: 1230px)': {
      width: '100%',
      maxWidth: '100%',
    },
    '& div': {
      width: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& img': {
      width: '100%',
    },
  },
});

export default styles;
