import React, { useCallback, useMemo } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import { formatTvl } from 'features/helpers/format';
import { byDecimals } from 'features/helpers/bignumber';
import styles from './styles';
import PoolPaused from './PoolPaused/PoolPaused';
import PoolTitle from './PoolTitle/PoolTitle';
import LabeledStat from './LabeledStat/LabeledStat';
import ApyStats from './ApyStats/ApyStats';
import { usePoolApr } from '../../../stake/redux/subscription';
import { PoolBoosts } from './PoolBoosts/PoolBoosts';
import { getRetireReason } from './RetireReason/RetireReason';
import { Button } from '@material-ui/core';
import { useConnectWallet } from '../../../home/redux/connectWallet.js';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import { getNetworkFriendlyId } from '../../../helpers/getNetworkData.js';

const useStyles = makeStyles(styles);

const PoolSummary = ({
  pool,
  launchpool,
  toggleCard,
  balanceSingle,
  sharesBalance,
  apy,
  fetchBalancesDone,
  fetchApysDone,
  fetchVaultsDataDone,
  multipleLaunchpools = false,
  actionsIsOpen = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { connected, networkId } = useConnectWallet();

  const launchpoolApr = usePoolApr(launchpool ? launchpool.id : null);
  const vaultStateTitle = useMemo(() => {
    let state =
      pool.status === 'eol'
        ? t(getRetireReason(pool.retireReason))
        : pool.depositsPaused
        ? t('Vault-DepositsPausedTitle')
        : null;

    if (launchpool) {
      state = t('Stake-BoostedBy', { name: launchpool.name });
    }

    if (pool.experimental) {
      state = t('Vault-Experimental');
    }

    return state === null ? (
      ''
    ) : (
      <PoolPaused
        message={t(state)}
        isBoosted={!!launchpool}
        isExperimental={!!pool.experimental}
      />
    );
  }, [pool, launchpool, t]);

  const balanceUsd =
    balanceSingle > 0 && fetchVaultsDataDone ? formatTvl(balanceSingle, pool.oraclePrice) : '';
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
    pool.tokenDecimals
  );
  const depositedUsd =
    deposited > 0 && fetchVaultsDataDone ? formatTvl(deposited, pool.oraclePrice) : '';
  // const onSummaryClick = useCallback(
  //   e => {
  //     if (!e.target || !e.target.classList.contains('tooltip-toggle')) {
  //       toggleCard();
  //     }
  //   },
  //   [toggleCard]
  // );

  return (
    // <AccordionSummary
    //   className={
    //     pool.status === 'eol'
    //       ? classes.detailsRetired
    //       : pool.depositsPaused
    //       ? classes.detailsPaused
    //       : classes.details
    //   }
    //   style={{ justifyContent: 'space-between' }}
    //   onClick={onSummaryClick}
    // >
    <div
      className={
        pool.status === 'eol'
          ? classes.detailsRetired
          : pool.depositsPaused
          ? classes.detailsPaused
          : classes.details
      }
      style={{ justifyContent: 'space-between' }}
    >
      <Grid container alignItems="center">
        {vaultStateTitle}
        <PoolBoosts poolName={pool.name} earnedTokenAddress={pool.earnedTokenAddress} />
        <Grid item xs={12} className={`${classes.item} ${classes.itemTitle}`}>
          <PoolTitle
            name={pool.name}
            logo={pool.logo}
            poolId={pool.id}
            poolChain={pool.chain}
            description={t('Vault-Description', { vault: pool.tokenDescription })}
            launchpool={launchpool}
            addLiquidityUrl={pool.addLiquidityUrl}
            removeLiquidityUrl={pool.removeLiquidityUrl}
            buyTokenUrl={pool.buyTokenUrl}
            assets={pool.assets}
            multipleLaunchpools={multipleLaunchpools}
          />
        </Grid>
        <Grid item xs={3} className={`${classes.item} ${classes.itemBalances}`}>
          {connected ? (
            <LabeledStat
              // value={formatDecimals(deposited)}
              // subvalue={depositedUsd}
              value={depositedUsd ? depositedUsd : '0'}
              label={t('Vault-Deposited')}
              isLoading={!fetchBalancesDone}
              className={classes.itemInner}
            />
          ) : (
            <LabeledStat
              // value={formatDecimals(deposited)}
              // subvalue={depositedUsd}
              value={'Not Connected'}
              label={t('Vault-Deposited')}
              isLoading={false}
              className={classes.itemInner}
            />
          )}
        </Grid>
        <Grid item xs={2} className={`${classes.item} ${classes.itemBalances}`}>
          {/*<LabeledStat
            value={formatDecimals(balanceSingle)}
            subvalue={balanceUsd}
            label={t('Vault-Wallet')}
            isLoading={!fetchBalancesDone}
            className={classes.itemInner}
          />*/}
          <LabeledStat
            value={formatTvl(pool.tvl, pool.oraclePrice)}
            // label={t('Vault-TVL')}
            label={t('Total Value Invested')}
            isLoading={!fetchVaultsDataDone}
            className={classes.itemInner}
          />
        </Grid>
        <ApyStats
          apy={apy}
          launchpoolApr={launchpoolApr}
          isLoading={!fetchApysDone}
          itemClasses={`${classes.item} ${classes.itemStats}`}
          itemInnerClasses={classes.itemInner}
          showOnly="yearly"
        />
        <Grid item xs={3} className={`${classes.item} ${classes.itemButtons}`}>
          <div className={classes.itemButtonWrapper}>
            {pool.id && (
              <Link
                to={`/vault/${pool.chain}/${pool.id}`}
                className={`${classes.showDetailButton} ${classes.showDetailButtonContained}`}
              >
                Start Earning Now
              </Link>
            )}
          </div>
          {/*<div className={classes.itemButtonWrapper}>
            <Button
              className={`${classes.showDetailButton}`}
              href={'https://charts.bogged.finance/?c=bsc&t=' + pool.tokenAddress}
              target="_blank"
            >
              Chart &nbsp;
              <img alt="Chart" src={require(`assets/img/chart.png`)} />
            </Button>
          </div>*/}
          {/*<Hidden xsDown>
            <div className={classes.itemButtonWrapper}>
              <Button
                className={`${classes.showDetailButton}`}
                onClick={async e => {
                  const provider = window.ethereum;
                  if (provider) {
                    await provider.request({
                      method: 'wallet_watchAsset',
                      params: {
                        type: 'ERC20',
                        options: {
                          address: pool.tokenAddress,
                          symbol: pool.token,
                          decimals: 18,
                          // image: require('images/' + pool.logo),
                        },
                      },
                    });
                  }
                  e.preventDefault();
                }}
              >
                <img alt="Add" src={require(`assets/img/add.png`)} />
                &nbsp;&nbsp;
                <img alt="Metamask" src={require(`assets/img/metamask.png`)} />
              </Button>
            </div>
          </Hidden>*/}
        </Grid>
      </Grid>
      {/*<div className={classes.expandArrow}>*/}
      {/*  <span> {actionsIsOpen ? '<' : '>'} </span>*/}
      {/*</div>*/}
    </div>
    // </AccordionSummary>
  );
};

const formatDecimals = number => {
  return number >= 10 ? number.toFixed(4) : number.isEqualTo(0) ? 0 : number.toFixed(8);
};

export default PoolSummary;
