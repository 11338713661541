import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { formatApy } from '../../../../helpers/format';
import { isNaN } from '../../../../helpers/bignumber';
import LabeledStat from '../LabeledStat/LabeledStat';
import { Fade, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const yearlyToMonthly = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 12) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const BreakdownTooltip = memo(({ rows }) => {
  const classes = useStyles();

  return (
    <>
      <table>
        <tbody>
          {rows.map(row => (
            <tr key={row.label}>
              <th className={classes.label}>{row.label}</th>
              <td className={classes.value}>{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        APY (Annual Percentage Yield) is the rate of return gained over the course of a year. It
        takes account of compounding, where as APR does not.
      </div>
    </>
  );
});

const YearlyBreakdownTooltip = memo(({ rates }) => {
  const rows = [];
  const { t } = useTranslation();

  if ('vaultApr' in rates) {
    rows.push({
      label: t('Vault-Breakdown-VaultApr'),
      value: rates.vaultApr,
    });
  }

  if ('tradingApr' in rates) {
    rows.push({
      label: t('Vault-Breakdown-TradingApr'),
      value: rates.tradingApr,
    });
  }

  if ('boostApr' in rates) {
    rows.push({
      label: t('Vault-Breakdown-BoostApr'),
      value: rates.boostApr,
    });
  }

  return <BreakdownTooltip rows={rows} />;
});

const DailyBreakdownTooltip = memo(({ rates }) => {
  const rows = [];
  const { t } = useTranslation();

  if ('vaultDaily' in rates) {
    rows.push({
      label: t('Vault-Breakdown-VaultDaily'),
      value: rates.vaultDaily,
    });
  }

  if ('tradingDaily' in rates) {
    rows.push({
      label: t('Vault-Breakdown-TradingDaily'),
      value: rates.tradingDaily,
    });
  }

  if ('boostDaily' in rates) {
    rows.push({
      label: t('Vault-Breakdown-BoostDaily'),
      value: rates.boostDaily,
    });
  }

  return <BreakdownTooltip rows={rows} />;
});

const MonthlyBreakdownTooltip = memo(({ rates }) => {
  const rows = [];
  const { t } = useTranslation();

  if ('vaultMonthly' in rates) {
    rows.push({
      label: t('Vault-Breakdown-VaultMonthly'),
      value: rates.vaultMonthly,
    });
  }

  if ('tradingMonthly' in rates) {
    rows.push({
      label: t('Vault-Breakdown-TradingMonthly'),
      value: rates.tradingMonthly,
    });
  }

  if ('boostMonthly' in rates) {
    rows.push({
      label: t('Vault-Breakdown-BoostMonthly'),
      value: rates.boostMonthly,
    });
  }

  return <BreakdownTooltip rows={rows} />;
});

const LabeledStatWithTooltip = memo(({ tooltip, label, ...passthrough }) => {
  const classes = useStyles();

  return tooltip ? (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      title={tooltip}
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      classes={{ tooltip: classes.tooltip }}
    >
      <LabeledStat
        label={
          <>
            {label} <i className="fas fa-info-circle" />
          </>
        }
        {...passthrough}
      />
    </Tooltip>
  ) : (
    <LabeledStat label={label} {...passthrough} />
  );
});

export const apyValues = (apy, launchpoolApr, isBoosted) => {
  const values = {};

  values.totalApy = apy.totalApy;

  if ('vaultApr' in apy && apy.vaultApr) {
    values.vaultApr = apy.vaultApr;
    values.vaultDaily = apy.vaultApr / 365;
    values.vaultMonthly = apy.vaultApr / 12;
  }

  if ('tradingApr' in apy && apy.tradingApr) {
    values.tradingApr = apy.tradingApr;
    values.tradingDaily = apy.tradingApr / 365;
    values.tradingMonthly = apy.tradingApr / 12;
  }

  if ('vaultAprDaily' in values || 'tradingAprDaily' in values) {
    values.totalDaily = (values.vaultDaily || 0) + (values.tradingDaily || 0);
  } else {
    values.totalDaily = yearlyToDaily(values.totalApy);
  }

  if ('vaultAprMonthly' in values || 'tradingAprMonthly' in values) {
    values.totalMonthly = (values.vaultMonthly || 0) + (values.tradingMonthly || 0);
  } else {
    values.totalMonthly = yearlyToMonthly(values.totalApy);
  }

  if (isBoosted) {
    values.boostApr = launchpoolApr;
    values.boostDaily = launchpoolApr / 365;
    values.boostMonthly = launchpoolApr / 12;
    values.boostedTotalApy = values.boostApr ? values.totalApy + values.boostApr : 0;
    values.boostedTotalDaily = values.boostDaily ? values.totalDaily + values.boostDaily : 0;
  }

  const formatted = Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      const formattedValue = key.toLowerCase().includes('daily')
        ? formatApy(value, 4)
        : formatApy(value);
      return [key, formattedValue];
    })
  );

  return [values, formatted];
};

const ApyStats = ({
  apy,
  launchpoolApr,
  isLoading = false,
  itemClasses,
  itemInnerClasses,
  showOnly,
  depositedUsd,
}) => {
  const { t } = useTranslation();
  const isBoosted = !!launchpoolApr;
  let needsApyTooltip = false;
  let needsDailyTooltip = false;

  const [values, formatted] = apyValues(apy, launchpoolApr, isBoosted);

  return (
    <>
      {(!showOnly || showOnly === 'yearly') && (
        <Grid item xs={2} className={itemClasses}>
          <LabeledStatWithTooltip
            value={
              depositedUsd ? '$' + (values.totalApy * depositedUsd).toFixed(2) : formatted.totalApy
            }
            label={t('Vault-APY')}
            tooltip={
              !isLoading && needsApyTooltip ? <YearlyBreakdownTooltip rates={formatted} /> : null
            }
            boosted={isBoosted ? formatted.boostedTotalApy : ''}
            isLoading={isLoading}
            className={`tooltip-toggle ${itemInnerClasses}`}
          />
        </Grid>
      )}
      {(!showOnly || showOnly === 'monthly') && (
        <Grid item xs={2} className={itemClasses}>
          <LabeledStatWithTooltip
            value={
              depositedUsd
                ? '$' + (values.totalMonthly * depositedUsd).toFixed(2)
                : formatted.totalMonthly
            }
            label={t('Vault-APYMonthly')}
            tooltip={
              !isLoading && needsDailyTooltip ? <MonthlyBreakdownTooltip rates={formatted} /> : null
            }
            boosted={isBoosted ? formatted.boostedTotalMonthly : ''}
            isLoading={isLoading}
            className={`tooltip-toggle ${itemInnerClasses}`}
          />
        </Grid>
      )}
      {(!showOnly || showOnly === 'daily') && (
        <Grid item xs={2} className={itemClasses}>
          <LabeledStatWithTooltip
            value={
              depositedUsd
                ? '$' + (values.totalDaily * depositedUsd).toFixed(2)
                : formatted.totalDaily
            }
            label={t('Vault-APYDaily')}
            tooltip={
              !isLoading && needsDailyTooltip ? <DailyBreakdownTooltip rates={formatted} /> : null
            }
            boosted={isBoosted ? formatted.boostedTotalDaily : ''}
            isLoading={isLoading}
            className={`tooltip-toggle ${itemInnerClasses}`}
          />
        </Grid>
      )}
    </>
  );
};

export default memo(ApyStats);
