export const avalanchePools = [
  {
    id: 'snowpegs-snowavax-avax',
    name: 'AVAX',
    token: 'SNOWAVAX-AVAX LP',
    tokenDescription: 'snowpegs.com',
    tokenAddress: '0x705432071bBC04da194991bC6Ce347dBA1752CCC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'snowpegsSNOWAVAX-AVAX',
    earnedTokenAddress: '0xEe9025C70E979fF54B0b4119E4Af7C4FD62dC158',
    earnContractAddress: '0xEe9025C70E979fF54B0b4119E4Af7C4FD62dC158',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snowpegs-snowavax-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'snowpegs',
    assets: ['SNOWAVAX', 'AVAX'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(245, 23, 70)',
    zapTokens: ['AVAX'],
    addLiquidityUrl: 'https://swap.peghub.com/add/ETH/0xDA1d9C79240003195d0a67f202efcCCC3F78b994',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=ETH&outputCurrency=0xDA1d9C79240003195d0a67f202efcCCC3F78b994',
  },
  {
    id: 'snowpegs-snowsol-sol',
    name: 'SOL',
    token: 'SNOWSOL-SOL LP',
    tokenDescription: 'snowpegs.com',
    tokenAddress: '0xEc58150DE32b83274cf63010b5bB1fc6d49159df',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'snowpegsSNOWSOL-SOL',
    earnedTokenAddress: '0xF3357b7db74AF82213EE7229bD45204d27E097f7',
    earnContractAddress: '0xF3357b7db74AF82213EE7229bD45204d27E097f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snowpegs-snowsol-sol',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'snowpegs',
    assets: ['SNOWSOL', 'SOL'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(245, 23, 70)',
    zapTokens: ['AVAX', 'SOL'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=ETH&outputCurrency=0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  },
  {
    id: 'snowpegs-snowlink-link',
    name: 'LINK',
    token: 'SNOWLINK-LINK LP',
    tokenDescription: 'snowpegs.com',
    tokenAddress: '0xCf21480eDf195be08DCaaC38eCAD07e19375A2Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'snowpegsSNOWLINK-LINK',
    earnedTokenAddress: '0xEF958860Eeb4C2Eb8749a99AB4a24F75772d8B4F',
    earnContractAddress: '0xEF958860Eeb4C2Eb8749a99AB4a24F75772d8B4F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snowpegs-snowlink-link',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'snowpegs',
    assets: ['SNOWLINK', 'LINK'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    bgColor: 'rgb(245, 23, 70)',
    zapTokens: ['AVAX', 'LINK'],
    addLiquidityUrl:
      'https://swap.peghub.com/add/0x5947BB275c521040051D82396192181b413227A3/0x522348779DCb2911539e76A1042aA922F9C47Ee3',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=ETH&outputCurrency=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  },
];
