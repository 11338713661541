const styles = theme => ({
  container: {
    fontWeight: 'bold',
  },
  inputGroup: {
    paddingTop: '10px',
    paddingBottom: '10px',
    '& .label': {
      marginBottom: '5px',
    },
    '& .MuiOutlinedInput-root': {
      width: '70%',
      background: 'transparent',
      height: '40px',
      '& input': {
        textAlign: 'center',
      },
    },
  },
  inputGroupWithBorder: {
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    marginTop: '10px',
  },
  statsGroup: {
    marginTop: '10px',
    '& table': {
      width: '100%',
    },
    '& td': {
      width: '50%',
    },
  },
});

export default styles;
