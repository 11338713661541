const styles = theme => ({
  container: {
    paddingTop: '4px',
  },
  subcontainer: {
    backgroundColor: 'rgba(245, 23, 71, 0.3)',
    mixBlendMode: 'hard-light',
    marginBottom: '10px',
    padding: '25px 15px',
    height: '83px',
    '& img': {
      height: '30px',
    },
  },
  tvl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '12px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  titleLoader: {
    marginLeft: '0',
    width: '80%',
  },
  subtitle: {
    letterSpacing: '0',
    lineHeight: '8px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
    },
    color: theme.palette.text.secondary,
    marginTop: '0',
  },
  text: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '16px',
    fontWeight: '550',
    color: theme.palette.text.red,
    paddingTop: '1px',
    display: 'flex',
    alignItems: 'center',
  },
  infinityIcon: {
    marginBottom: '-6px',
    paddingRight: '5px',
  },
});

export default styles;
