import React from 'react';

import Pools from 'features/vault/components/Pools/Pools';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import VideoGuide from '../../components/VideoGuide/VideoGuide.js';
import { makeStyles } from '@material-ui/core/styles';
import vaultsStyles from './vaultsStyles.js';

const useStyles = makeStyles(vaultsStyles);

export default function VaultsPage() {
  const classes = useStyles();

  return (
    <>
      {/*<Disclaimer />*/}
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          Earning high yields has never been easier! Pick your favourite crypto below and get
          started now.
        </div>
        {/*<div className={classes.separator}>&nbsp;</div>*/}
      </div>

      <VideoGuide />

      <Pools fromPage="home" />
    </>
  );
}
